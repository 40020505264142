.ly_section {
  padding: 100px 0;
}
 
.ly_section_inner {
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto;
}
 
.el_lv2Heading {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 70px;
}
 
.card_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
 
.card_items > li {
  width: 31%;
  margin-right: 3.5%;
  margin-bottom: 35px;
}
 
.card_items > li:nth-of-type(3n) {
  margin-right: 0;
}
 
.card_item {
  position: relative;
  display: block;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
 
.card_item:hover .card_item_imgWrapper::before {
  opacity: 1;
}
 
.card_item:hover .card_item_imgWrapper::after {
  content: "Read More";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
}
 
.card_item_imgWrapper {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}
 
.card_item_imgWrapper::before, .card_item_imgWrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fafafa;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 2rem;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}
 
.card_item_imgWrapper > img {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -o-object-fit: cover;
  object-fit: cover;
}
 
.card_item_body {
  height: 160px;
  padding: 15px;
}
 
.card_item_body > .card_item_time {
  font-size: 1.6rem;
  margin-bottom: 5px;
  display: block;
}
 
.card_item_body > .card_item_ttl {
  color: #333;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 5px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
 
.card_item_body > .card_item_txt {
  color: #777;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 10px;
}